<template>
  <div
    :class="{
      disabledComponent:
        !isCsatSurveyAvailable || isProjectCancelled || isArchived,
    }"
  >
    <template v-if="!isAgent">
      <SubmitFeedbackTemplate
        :project="project"
        :survey-to-load="surveyToLoad"
      />
    </template>
    <div
      v-else
      class="body d-flex flex-column"
      style="padding-top: 10px; border: none !important"
    >
      <div class="col-12">
        <SubmitFeedbackTemplate
          :project="project"
          :surveyToLoad="surveyToLoad"
        />
        <div
          v-if="surveyToLoad"
          class="d-flex justify-content-between px-4"
          style="padding-bottom: 32px; border-bottom: 1px solid #dde4ea"
        >
          <div class="align-left">
            <span v-if="surveyToLoad?.survey_completed" class="ml-2 mt-0">
              <i class="fa fa-comments-o fa-lg" style="color: #17c2d7"></i>
              Feedback provided by {{ fullContactName }}
            </span>
            <span v-else-if="isPartialFeedbackSubmitted" class="ml-2 mt-0">
              <i
                class="fa fa-exclamation-circle fa-lg"
                style="color: #f09819"
              ></i>
              Incomplete response from {{ fullContactName }}
            </span>
            <span v-else class="ml-2 mt-0">
              <i
                class="fa fa-exclamation-circle fa-lg"
                style="color: #f09819"
              ></i>
              Pending response from {{ fullContactName }}
            </span>
          </div>
          <div v-if="allSentSurveys?.length > 1" class="align-right">
            <span @click="onPrevClick"
              ><i
                class="icon-arrow-left cursor-pointer mr-2 fa-2x icon-custom-medium"
              ></i
            ></span>
            <span @click="onNextClick"
              ><i
                class="icon-arrow-right cursor-pointer fa-2x icon-custom-medium"
              ></i
            ></span>
          </div>
        </div>
      </div>
      <div class="px-4 mt-4">
        <div class="d-flex align-items-center">
          <h4
            class="d-inline-block"
            style="margin-bottom: 0; line-height: 1.7rem"
          >
            Send CSAT survey
          </h4>
        </div>
        <div class="form-group mt-4">
          <SurveyEmailEditor
            v-if="isAgent"
            :project="project"
            :surveyIdToSend="surveyIdToSend"
            :surveysStatus="surveysStatus"
          >
          </SurveyEmailEditor>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SurveyEmailEditor from "./SurveyEmailEditor.vue";
import SubmitFeedbackTemplate from "./SubmitFeedbackTemplate.vue";
import { isWorkspaceProfessionalLevel } from "@/utils/helper";
import eventBus, { channels } from "@/eventBus";

export default {
  name: "FeedbackTab",
  components: {
    SurveyEmailEditor,
    SubmitFeedbackTemplate,
  },
  props: {
    template: {
      type: Object,
      default() {
        return {};
      },
    },
    project: {
      type: Object,
      default: () => ({}),
    },
    surveysFeedback: {
      type: Array,
      default: () => [],
    },
    surveysStatus: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      editorText: "",
      placeholder: "Survey Email",
      shouldEnableSubmitButton: false,
      chosenSurveyIndex: 0,
    };
  },
  computed: {
    isCsatSurveyAvailable() {
      return isWorkspaceProfessionalLevel(this.$auth.tenant.subscription);
    },
    isAgent() {
      return this.$auth.user.isAgent;
    },
    allSentSurveys() {
      const surveys = this.surveysFeedback.filter((item) => {
        if (this.$auth.user.isAgent) {
          return item.project_id === this.project.id;
        }
      });
      return surveys;
    },
    surveyToLoad() {
      if (this.isAgent) {
        return this.allSentSurveys[this.chosenSurveyIndex];
      } else {
        const survey = this.surveysFeedback.find(
          (item) =>
            item.project_id === this.project.id &&
            item.contact_email === this.$auth.user.email
        );
        return survey ? survey : { surveyItems: [] };
      }
    },
    matchedContact() {
      return this.project?.contacts?.find(
        (contact) => contact.email === this.surveyToLoad?.contact_email
      );
    },
    fullContactName() {
      if (this.matchedContact) {
        return `${this.matchedContact.firstname} ${this.matchedContact.lastname}`;
      } else {
        return "";
      }
    },
    surveyIdToSend() {
      const alreadySentSurvey = this.surveysStatus?.find(
        (surveyStatus) => surveyStatus.project_id === this.project.id
      );
      return alreadySentSurvey ? alreadySentSurvey.survey_id : undefined;
    },
    isPartialFeedbackSubmitted() {
      let partialFeedbackSubmitted = false;
      this.surveyToLoad?.surveyItems?.forEach((feedback) => {
        if (feedback.is_star_rating && feedback.rating > 0) {
          partialFeedbackSubmitted = true;
          return;
        }
      });
      return partialFeedbackSubmitted && !this.surveyToLoad?.survey_completed;
    },
    isProjectCancelled() {
      return this.project.cancelled;
    },
    isArchived() {
      return Boolean(this.project.archived);
    },
  },
  methods: {
    onEditorChange(noteText) {
      this.editorText = noteText;
    },
    onNextClick() {
      this.chosenSurveyIndex =
        (this.chosenSurveyIndex + 1) % this.allSentSurveys.length;
      return this.chosenSurveyIndex;
    },
    onPrevClick() {
      this.chosenSurveyIndex = this.chosenSurveyIndex - 1;
      if (this.chosenSurveyIndex < 0) {
        this.chosenSurveyIndex = this.allSentSurveys.length - 1;
      }
      return this.chosenSurveyIndex;
    },
  },
  mounted() {
    if (!this.isCsatSurveyAvailable)
      eventBus.$emit(channels.upgradeSubscription, { allowDismissal: true });
  },
};
</script>

<style scoped>
li {
  border: none;
  padding: 1rem 0.5rem !important;
}

.star-rating {
  text-wrap: nowrap;
}

.survey-question {
  font-weight: 400;
  color: #494949;
}

.btn-primary {
  border-color: #1773f6;
}
</style>
