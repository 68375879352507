<template>
  <wrapper-page>
    <template v-slot:MainContentHeaderActions>
      <div
        class="col-md-8 col-sm-12 text-right hidden-xs"
        v-if="$auth.user.isAgent"
      >
        <router-link
          v-if="isMultiDeliverableType"
          :to="{ path: `/projects/${projectId}/multi-update` }"
          class="btn btn-outline custom-outline ml-4 bg-white"
        >
          <img
            src="/assets/images/stack.svg"
            style="
              height: 0.75rem;
              width: auto;
              margin-top: -2px;
              margin-right: 4px;
            "
          />
          <span> Multi-update </span>
        </router-link>
        <router-link
          :to="{ path: `/projects/${projectId}` }"
          class="btn btn-primary ml-2"
          ><i class="icon-equalizer"></i>
          <span>Project Settings</span></router-link
        >
        <router-link
          v-if="project?.client_id"
          :to="{ path: `/clients/${project.client_id}` }"
          class="btn btn-primary ml-2"
          ><i class="icon-settings"></i>
          <span>Client Settings</span></router-link
        >
      </div>
    </template>

    <div class="d-flex align-items-center" style="padding-bottom: 1.5rem">
      <h1
        class="d-inline-block mr-2"
        style="margin-bottom: 0; line-height: 1.7rem"
      >
        {{ project.name }}
      </h1>
      <span
        v-if="project.archived"
        :class="[
          'badge',
          'xs',
          project.cancelled ? 'badge-disabled' : 'badge-primary',
        ]"
        >{{ project.cancelled ? "cancelled" : "Archived" }}</span
      >
    </div>
    <div class="row clearfix">
      <div class="col-lg-12">
        <div class="card">
          <div class="body">
            <ul class="nav nav-tabs">
              <li class="nav-item">
                <a
                  class="nav-link"
                  data-toggle="tab"
                  href="#timelines"
                  :class="activeTab == 'timelines' ? `active` : ``"
                  @click="activeTab = 'timelines'"
                  >Timelines</a
                >
              </li>
              <li class="nav-item">
                <a
                  class="nav-link"
                  data-toggle="tab"
                  href="#deliverables"
                  :class="activeTab == 'deliverables' ? `active` : ``"
                  @click="activeTab = 'deliverables'"
                  >Deliverables</a
                >
              </li>
              <li class="nav-item" v-if="showFeedbackTab">
                <a
                  class="nav-link"
                  data-toggle="tab"
                  href="#feedback"
                  :class="activeTab == 'feedback' ? `active` : ``"
                  @click="activeTab = 'feedback'"
                >
                  Feedback
                </a>
              </li>
            </ul>
            <div v-show="activeTab === 'timelines'" class="tab-content">
              <ProjectTimelinesTable
                v-if="isProjectsFetched"
                :project="project"
                :showSearchBar="false"
                identifier="project-timelines-js-data-table"
              />
            </div>
            <div v-show="activeTab === 'deliverables'" class="tab-content">
              <ProjectDeliverables
                v-if="isProjectsFetched"
                :deliverables="deliverables"
                :originalDeliverablesString="null"
                :project="project"
                ref="project-deliverables"
                readonly
                is-brief
              />
            </div>
            <div
              v-show="activeTab === 'feedback'"
              class="tab-content"
              id="feedback"
            >
              <div class="col-md-12 col-sm-12 col-lg-9 pt-2 pl-4">
                <feedback-tab
                  :project="project"
                  :surveysFeedback="surveysFeedback"
                  :surveysStatus="surveysStatus"
                ></feedback-tab>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </wrapper-page>
</template>

<script>
import {
  getDeliverableFiles,
  getProjectById,
  getProjectDeliverables,
} from "@/apis/projects";
import WrapperPage from "../components/layout/WrapperPage.vue";
import ProjectTimelinesTable from "../components/ui/ProjectTimelinesTable.vue";
import timeMixin from "../mixins/time";
import workflowMixin from "../mixins/workflow";
import ProjectDeliverables from "@/components/projects/ProjectDeliverables.vue";
import FeedbackTab from "@/components/ui/FeedbackSurvey/FeedbackTab.vue";
import { getAllSurveyWithFeedback, surveysStatus } from "@/apis/survey";
import eventBus, { channels } from "@/eventBus";
import { THUMBNAIL_EXTENSIONS } from "@/utils/app.constant";

export default {
  name: "ListDeliverablesPage",
  components: {
    FeedbackTab,
    ProjectDeliverables,
    WrapperPage,
    ProjectTimelinesTable,
  },
  mixins: [timeMixin, workflowMixin],
  props: {},
  data() {
    return {
      activeTab: "timelines",
      project: {},
      deliverables: [],
      projectId: this.$route.params.projectId,
      filterString: "",
      showAsGrid: false,
      isProjectsFetched: false,
      isLoadingDetails: false,
      surveysFeedback: [],
      surveysStatus: [],
    };
  },
  async mounted() {
    if (window.location.hash === "#feedback") {
      this.activeTab = "feedback";
    }

    await this.refresh();

    eventBus.$on(channels.fetchAllSurveyWithFeedback, async () => {
      this.surveysFeedback = await getAllSurveyWithFeedback(this.projectId);
    });
    eventBus.$on(channels.fetchAllSurveyStatus, async () => {
      this.surveysStatus = await surveysStatus();
    });
  },
  beforeDestroy() {
    eventBus.$off(channels.fetchAllSurveyWithFeedback);
    eventBus.$off(channels.fetchAllSurveyStatus);
  },
  computed: {
    showFeedbackTab() {
      if (this.$auth.user.isAgent) return true;

      return this.project?.is_survey_sent;
    },
    isMultiDeliverableType() {
      return this.project.deliverable_type === "Multi";
    },
  },
  methods: {
    async refresh() {
      this.isLoadingDetails = true;
      try {
        const project = await getProjectById(this.projectId);
        this.fetchAndAssignWorkflowData(project.projectWorkflows)
          .then((updatedWorkflowArray) => {
            if (updatedWorkflowArray.length) {
              project.deliverables = [...updatedWorkflowArray].sort(
                (a, b) => a?.last_save_time - b?.last_save_time
              );
              this.isProjectsFetched = true;
            }
          })
          .catch((error) => {
            console.error("Error occurred:", error);
          });
        this.project = project;
        this.refreshAndLoadDeliverables();

        this.surveysFeedback = await getAllSurveyWithFeedback(this.projectId);
        this.surveysStatus = await surveysStatus();
      } finally {
        this.isLoadingDetails = false;
      }
    },
    async refreshAndLoadDeliverables() {
      const result = await getProjectDeliverables(this.projectId);
      if (result && result.length > 0) {
        this.deliverables = result
          .map((d) => {
            if (!d.files) d.files = [];

            return d;
          })
          .sort((a, b) => a?.last_save_time - b?.last_save_time);

        this.deliverables.forEach((deliverable, i) => {
          if (!this.projectId || !deliverable.id) return;

          getDeliverableFiles(this.projectId, deliverable.id)
            .then((res) => {
              const files = res.map((f) => {
                const storageEndpoint = process.env.VUE_APP_AZ_STORAGE_ENDPOINT;
                const url = `${storageEndpoint}${
                  storageEndpoint.at(-1) !== "/" ? "/" : ""
                }documents/${f.path}`;
                const filename = url.substring(url.lastIndexOf("/") + 1);

                let thumbnail = null;
                const type = `.${filename.split(".")[1]?.toLowerCase()}`;
                if (THUMBNAIL_EXTENSIONS.includes(type)) {
                  thumbnail = url.replace(filename, `thumbnails/${filename}`);
                }

                return {
                  url,
                  size: f.size,
                  created_on: f.created_on,
                  thumbnail,
                  name: filename,
                  type,
                };
              });

              this.$set(this.deliverables, i, {
                ...deliverable,
                files,
                type: files.length ? "files" : "link",
              });
            })
            .catch((error) => {
              this.notifyError(error, "Error getting files for deliverable");
            });
        });
      } else if (this.$auth.user.isAgent) {
        this.deliverables = [];
      }
    },
    search: function (searchString) {
      this.filterString = searchString;
    },
  },
  watch: {},
};
</script>

<style>
.custom-outline {
  color: #16a1b8;
  border-color: #16a1b8;
}
</style>
