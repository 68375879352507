var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.field.label && _vm.showLabel)?_c('b',[(_vm.field.type == 'checkbox')?_c('label',{staticStyle:{"width":"100%","text-align":"center"},attrs:{"for":`input-${_vm.field.name}`}},[_vm._v(_vm._s(_vm.field.label))]):_c('label',{attrs:{"for":`input-${_vm.field.name}`}},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.field.label)}})])]):_vm._e(),_c('div',{staticClass:"input-group mb-3"},[(_vm.field.type === 'date')?_c('date-time-picker',{attrs:{"value":_vm.processedValue,"type":"date","format":"YYYY-MM-DD","placeholder":"Choose date"},on:{"input":(e) => _vm.emitDateChange(e)}}):(_vm.field.type !== 'select')?_c('input',_vm._b({ref:"input",staticClass:"form-control",attrs:{"id":`input-${_vm.field.name}`,"name":_vm.field.name,"type":_vm.field.type || 'text',"aria-label":_vm.field.name,"placeholder":_vm.field.placeholder || _vm.field.label,"readonly":_vm.readonly || _vm.field.readonly,"maxlength":_vm.field.maxLength,"min":_vm.field.min,"max":_vm.field.max,"aria-describedby":"basic-addon1"},domProps:{"value":_vm.value},on:{"input":(e) =>
          _vm.$emit(
            'input',
            _vm.field.type == 'checkbox' ? e.target.checked : e.target.value
          ),"blur":(e) =>
          _vm.$emit(
            'blur',
            _vm.field.type == 'checkbox' ? e.target.checked : e.target.value
          )}},'input',_vm.$attrs,false)):_c('select',_vm._b({staticClass:"form-control",attrs:{"id":`input-${_vm.field.name}`,"name":_vm.field.name,"aria-label":_vm.field.name,"aria-describedby":"basic-addon1"},on:{"input":(e) => _vm.$emit('input', e.target.value)}},'select',_vm.$attrs,false),_vm._l((_vm.field.options),function(option){return _c('option',{key:`${_vm.field.name} ${option.value} ${option.text}`,attrs:{"disabled":option.value === 0},domProps:{"value":option.value,"selected":_vm.value == option.value}},[_vm._v(" "+_vm._s(option.text)+" ")])}),0),_c('div',{staticClass:"input-group-append"},[_vm._t("input-group-append")],2)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }