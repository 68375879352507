import langs from "langs";
import i18n from "@/i18n";

export const TIME_FORMAT = [
  "DD/MM/YYYY",
  "MM/DD/YYYY",
  "YYYY/DD/MM",
  "YYYY/MM/DD",
];
// Example merge tags and values
export const mergeTagsWithValue = (language = "en") => ({
  "client name": "Teslas",
  "contact name": "John",
  "user name": "Alice Jones",
  "user email": "alice.jones@yourcompany.com",
  "stage name": "Design",
  "revision number": i18n.t("mergeTags.constants.revision_number", language),
  "revision number #": "1",
  "revision number st": i18n.t(
    "mergeTags.constants.revision_number_st",
    language
  ),
  "stage revisions left": i18n.t(
    "mergeTags.constants.stage_revisions_left",
    language
  ),
  "stage revisions left #": "2",
  "stage revisions count": i18n.t(
    "mergeTags.constants.stage_revisions_count",
    language
  ),
  "allowed feedback days": "2 business days",
  "timeline link": "https://youragency.planeolo.com/projects/28/timeline",
  "review link": "https://yourreviewingplatform.com",
  "survey link":
    "How satisfied are you with the overall outcome of the project?",
  "project name": "Video edit",
  "recipient name": "John Smith",
  "recipient fname": "John",
  "recipient lname": "Smith",
  "recipient email": "john.smith@teslas.com",
  "deliverable name": "Final video",
  "deliverable link":
    "https://youragency.planeolo.com/projects/28/deliverables",
  "deliverable notes": "Here is the video with subtitles in Spanish",
  "email signature": `<p><b>Alice Jones</b><br>Senior Account Manager<br>Direct: +44 7 46512 323<br>Connect with me on LinkedIn</p>`,
  "business days overdue": "2",
  "workspace url": "https://youragency.planeolo.com",
});

export const SUPPORTED_LANGUAGES = ["en", "es", "it", "fr"];
export const ALL_LANGUAGES = langs.all();
export const LANGUAGES = langs
  .all()
  .filter((l) => SUPPORTED_LANGUAGES.includes(l["1"]));

export const THUMBNAIL_EXTENSIONS = [
  ".jpg",
  ".png",
  ".svg",
  ".gif",
  ".jpeg",
  ".bmp",
  ".webm",
  ".webp",
  ".ogg",
  ".mp4",
  ".avi",
  ".mkv",
  ".wmv",
];
