<template>
  <div class="row clearfix">
    <div class="col-12">
      <div class="card">
        <div class="header"><h2>Workflow Setup</h2></div>
        <div
          v-if="deliverables.length"
          class="d-flex"
          style="margin-bottom: -1px"
        >
          <div class="tab-bar-scroll">
            <ul class="nav nav-tabs deliverable-nav-tabs">
              <li
                class="nav-item px-1"
                v-for="(tab, index) in deliverables"
                :key="index"
              >
                <a
                  :class="
                    activeTab === tab.workflow_id
                      ? 'nav-link active'
                      : 'nav-link'
                  "
                  data-toggle="tab"
                  :href="'#' + tab.workflow_id"
                  style="position: relative; min-width: 100px"
                  @click="setActiveTab(tab.workflow_id)"
                >
                  <span class="p-2"
                    ><b>{{ tab.workflow_title }}</b></span
                  >
                  <i
                    v-if="activeTab === tab.workflow_id"
                    class="fa fa-edit"
                    @click="onTabEdit(tab.workflow_id)"
                    style="position: absolute; top: 5px; right: 5px"
                  ></i>
                </a>
              </li>
            </ul>
          </div>
          <div class="ml-auto">
            <button
              @click="showChooseTimelineStructureModal = true"
              type="button"
              class="btn btn-primary"
              title="Add New Deliverable"
            >
              <i class="fa fa-plus"></i>
            </button>
            <!-- <a class="plus-button" href="#" @click.prevent="addNewTab">
              <i class="fa fa-plus"></i>
            </a> -->
          </div>
        </div>
        <div v-if="deliverables.length" class="tab-content mt-0">
          <div class="tab-pane show vivify fadeIn active">
            <!-- Menu bar -->
            <div class="body">
              <div class="row clearfix">
                <div style="width: 32px"></div>
                <div class="col-4">
                  <button
                    type="button"
                    class="btn btn-primary ml-3"
                    title="Append Stage with revisions"
                    @click="appendStageWithRevs"
                    :disabled="isSubscriptionReadOnly()"
                  >
                    <span class="sr-only">Append stage</span>
                    <i class="fa fa-plus"></i> <span>Add Stage</span>
                  </button>
                </div>
                <div class="col-sm-3 col-lg-2">
                  <button
                    v-if="isEditingWorkflow"
                    type="button"
                    class="btn btn-info ml-1"
                    @click="isEditingWorkflow = false"
                  >
                    <i class="fa fa-arrows-v"></i> <span>Reorder stages</span>
                  </button>
                  <button
                    v-if="!isEditingWorkflow"
                    type="button"
                    class="btn btn-info"
                    @click="isEditingWorkflow = true"
                  >
                    <i class="fa fa-edit"></i> <span>Edit Stages</span>
                  </button>
                </div>
                <div class="col-3">
                  <label class="fancy-checkbox">
                    <input
                      type="checkbox"
                      name="checkbox"
                      v-model="showTargetCompletion"
                    />
                    <span>Enable Target dates</span>
                  </label>
                </div>

                <div class="col text-right mr-2">
                  <label class="text-muted" for="ddlTemplates"
                    >Workflow presets</label
                  >
                  <button
                    id="ddlTemplates"
                    class="btn btn-outline-secondary dropdown-toggle p-1 ml-1"
                    type="button"
                    data-toggle="dropdown"
                  >
                    Choose template
                  </button>
                  <div class="dropdown-menu">
                    <a
                      v-for="(template, index) in templates"
                      :key="index"
                      class="dropdown-item"
                      @click="createWorkflowFromTemplateIndex(index)"
                      >{{ template.name }}</a
                    >
                  </div>
                </div>
              </div>
            </div>
            <!-- Stages section -->
            <div class="body">
              <div style="min-height: 200px">
                <div class="row clearfix">
                  <div class="col-1" style="max-width: 35px"></div>
                  <div class="col-3">
                    <h6>Stages</h6>
                  </div>
                  <div class="col-sm-2 col-lg-2">
                    <h6>Revision rounds</h6>
                  </div>
                  <div v-if="showTargetCompletion" class="col-2">
                    <h6>Target Completion</h6>
                  </div>
                  <div class="col-2" style="max-width: 130px">
                    <h6>Deliverable</h6>
                  </div>
                  <div class="col-3">
                    <h6 id="workflow_qc_checklist">QC checklist</h6>
                  </div>
                </div>
                <div class="dd nestable">
                  <textarea
                    ref="taWorkflowOrder"
                    style="display: none"
                    v-model="taWorkflowOrder"
                  ></textarea>
                  <!-- required to store nestable changes, i.e., the order of the stages -->
                  <ol class="dd-list">
                    <li
                      class="dd-item"
                      :data-id="index"
                      v-for="(stage, index) in wfTemplate.tasks"
                      :key="index"
                    >
                      <!-- Show stages in a way that can be ordered...if user switched to 'ordering' and stages not yet started -->
                      <div v-if="canReorder(stage)" class="dd-handle">
                        <div class="row clearfix">
                          <div class="col-1"></div>
                          <div class="col-3">
                            <span>{{ stage.title }}</span>
                          </div>
                          <div class="col-sm-2 col-lg-2">
                            <span>{{ revisionsInStage(stage) }}</span>
                          </div>
                          <div v-if="showTargetCompletion" class="col-2">
                            <span>{{ stage.target_date }}</span>
                          </div>
                        </div>
                      </div>
                      <div v-else>
                        <div class="row clearfix">
                          <div class="col-1" style="max-width: 35px">
                            <button
                              v-if="isEditingWorkflow && isPending(stage)"
                              @click="removeStage(index)"
                              type="button"
                              class="btn btn-danger btn-sm"
                              title="Remove Stage"
                            >
                              <span class="sr-only">Remove stage</span>
                              <i class="fa fa-times"></i>
                            </button>
                          </div>
                          <div class="col-3">
                            <form-input-group
                              :field="{
                                vlabel: `Stage ${index}`,
                                name: `stage-${index}`,
                              }"
                              v-model="stage.title"
                            />
                          </div>
                          <div class="col-sm-2 col-lg-2">
                            <form-input-group
                              :field="{
                                name: `revisions-${index}`,
                                type: 'number',
                                min: 0,
                              }"
                              :value="revisionsInStage(stage)"
                              @input="updateStageRevisionCount(stage, $event)"
                              :readonly="
                                !canUpdateRevisions(stage) ||
                                stage.is_deliverable
                              "
                              style="min-width: 7em; max-width: 7em"
                            />
                          </div>
                          <div v-if="showTargetCompletion" class="col-2">
                            <form-input-group
                              :field="{
                                name: `target_date-${index}`,
                                type: 'date',
                              }"
                              v-model="stage.target_date"
                              :disabled="!canUpdateRevisions(stage)"
                              :clear-button="true"
                            />
                          </div>
                          <div class="col-2" style="max-width: 130px">
                            <label class="switch">
                              <input
                                :id="`checkbox-${index}`"
                                type="checkbox"
                                v-model="stage.is_deliverable"
                                :disabled="!canUpdateRevisions(stage)"
                                @change="
                                  ($event) => {
                                    if ($event.target.checked === true) {
                                      updateStageRevisionCount(stage, 0);
                                    }
                                  }
                                "
                              />
                              <span class="slider round"></span>
                            </label>
                          </div>
                          <div class="col-3">
                            <form-input-group
                              :field="qcChecklistFields"
                              :value="
                                stage.data ? stage.data.qcChecklistId : null
                              "
                              :disabled="stage.status"
                              @input="
                                (newValue) => {
                                  if (!stage.data) {
                                    stage.data = {};
                                  }
                                  stage.data.qcChecklistId = newValue;
                                }
                              "
                            />
                          </div>
                        </div>
                      </div>
                    </li>
                  </ol>
                </div>
              </div>
              <!-- Phase 2 Additional Costs
                      <div class="chatapp_detail text-center vivify pullLeft delay-150">
                          <div class="profile-image"><img ssrc="../assets/images/user.png" class="rounded-circle mb-3" alt=""></div>
                          <h5 class="mb-0">Louis Pierce</h5>                                
                          <small class="text-muted">Address: </small>
                          <p> San Francisco</p>
                          <small class="text-muted">Email address: </small>
                          <p>louispierce@example.com</p>
                          <small class="text-muted">Mobile: </small>
                          <p>+ 202-222-2121</p>
                          <button class="btn btn-round btn-success">View Profile</button>
                      </div> -->
            </div>
          </div>
        </div>
        <div
          v-else
          class="body d-flex align-items-center justify-content-center"
          style="min-height: 200px"
        >
          <div>
            <button
              @click="showChooseTimelineStructureModal = true"
              type="button"
              class="btn btn-primary"
              title="Add Contact"
            >
              <i class="fa fa-plus"></i>
              <span class="ml-2">Add Deliverable</span>
            </button>
          </div>
        </div>
      </div>
    </div>
    <ChooseTimelineStructureModal
      v-if="showChooseTimelineStructureModal"
      :templates="templates"
      :selectedDeliverable="selectedDeliverable"
      @close="onModalClose"
      @submit="onDeliverableSubmit"
      @delete="onDelete"
    />
  </div>
</template>

<script>
import FormInputGroup from "./FormInputGroup.vue";
import workflowMixin from "../../mixins/workflow";
import ChooseTimelineStructureModal from "./Modals/ChooseTimelineStructureModal.vue";

export default {
  name: "MultiWorkflowEditor",
  mixins: [workflowMixin],
  components: {
    FormInputGroup,
    ChooseTimelineStructureModal,
  },
  props: {
    workflow: {
      type: Object,
      default() {
        return {};
      },
    },
    multiDeliverables: {
      type: Array,
      default() {
        return [{}];
      },
    },
    templates: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      isEditingWorkflow: true,
      taWorkflowOrder: "",
      showTargetCompletion: false,
      activeTab: this.multiDeliverables[0]?.workflow_id,
      previousActiveTab: "",
      tabToBeEdited: "",
      deliverables: this.multiDeliverables,
      showChooseTimelineStructureModal: false,
      isLinear: false,
    };
  },
  computed: {
    wfTemplate() {
      const selectedDeliverable = this.deliverables.find(
        (deliverable) => deliverable.workflow_id === this.activeTab
      );
      return selectedDeliverable ? selectedDeliverable.workflow : {};
    },
    qcChecklistTemplates() {
      return this.$store.getters.qcChecklistTemplates;
    },
    qcChecklistFields() {
      const options = this.qcChecklistTemplates
        .filter((template) => template.id)
        .map((template) => ({
          text: template.name,
          value: template.id,
        }));
      options.push({
        text: "None",
        value: null,
      });
      return {
        type: "select",
        name: "qcChecklist",
        options,
      };
    },
    multiWfTemplate() {
      return this.deliverables;
    },
    selectedDeliverable() {
      return this.deliverables.find(
        (deliverable) => deliverable.workflow_id === this.tabToBeEdited
      );
    },
  },
  methods: {
    onModalClose() {
      this.showChooseTimelineStructureModal = false;
      this.tabToBeEdited = "";
    },
    onTabEdit(workflow_id) {
      this.tabToBeEdited = workflow_id;
      this.showChooseTimelineStructureModal = true;
    },
    setActiveTab(workflow_id) {
      if (this.activeTab !== workflow_id) {
        this.previousActiveTab = this.activeTab;
        this.activeTab = workflow_id;
      }
    },
    generateUniqueId() {
      const timestamp = Date.now().toString(36); // Convert current time to base36 string
      const randomChars = Math.random().toString(36).substr(2, 5); // Generate random characters
      return timestamp + randomChars;
    },
    onDeliverableSubmit({ template_id, workflow_title, selected, mode }) {
      if (mode === "add") {
        const newDeliverableId = this.generateUniqueId();
        const newTab = {
          workflow_id: newDeliverableId,
          workflow_title: workflow_title,
          isNew: true,
          workflow: {},
          isLinear: selected === "linear",
        };
        this.deliverables.push(newTab);
        this.previousActiveTab = this.activeTab;
        this.activeTab = newDeliverableId;

        const templateIndex = template_id
          ? this.templates.findIndex((t) => t.id == template_id)
          : -1;

        if (templateIndex !== -1) {
          this.$emit("wfChange", {
            workflow_id: newDeliverableId,
            wf: this.getWorkflowFromTemplate(this.templates[templateIndex]),
          });
        }
      } else {
        const selectedDeliverable = this.deliverables.find(
          (deliverable) => deliverable.workflow_id === this.tabToBeEdited
        );

        if (selectedDeliverable) {
          selectedDeliverable.workflow_title = workflow_title;
          selectedDeliverable.isLinear = selected === "linear" ? true : false;

          const templateIndex = template_id
            ? this.templates.findIndex((t) => t.id == template_id)
            : -1;

          if (templateIndex !== -1) {
            this.$emit("wfChange", {
              workflow_id: selectedDeliverable.workflow_id,
              wf: this.getWorkflowFromTemplate(this.templates[templateIndex]),
            });
          }
        }
      }
      this.onModalClose();
    },
    onDelete(workflow_id) {
      const selectedDeliverableIndex = this.deliverables.findIndex(
        (deliverable) => deliverable.workflow_id === workflow_id
      );

      if (selectedDeliverableIndex !== -1) {
        this.deliverables.splice(selectedDeliverableIndex, 1);
        this.activeTab =
          this.deliverables[this.deliverables.length - 1]?.workflow_id;
        this.$emit("delete", workflow_id);
        this.onModalClose();
      }
    },
    createWorkflowFromTemplate(template) {
      this.$emit("wfChange", this.getWorkflowFromTemplate(template));
    },
    createWorkflowFromTemplateIndex(index) {
      const template = this.templates[index];
      this.$emit("wfChange", {
        workflow_id: this.activeTab,
        wf: this.getWorkflowFromTemplate(template),
      });
    },
    validate: function () {
      if (!this.wfTemplate) return "No workflow";
      if (!this.wfTemplate.tasks) return "No stages";
      if (this.wfTemplate.tasks.length === 0) return "No stages";
      // Check that has at least 1 stage with 1+ revs
      if (
        !this.wfTemplate.tasks.find(
          (stage) => stage.tasks && stage.tasks.length > 0
        )
      )
        return "No stages with at least 1 revision";
      // No empty stage names
      if (
        this.wfTemplate.tasks.find(
          (stage) => !stage.title || stage.title.trim().length === 0
        )
      )
        return "Empty stage name";

      return true;
    },
    /**
     * Can the user re-order the given stage?
     * Yes if the user clicked on the 're-order stages' button
     * and the stage is not yet started
     * @param Object stage
     */
    canReorder(stage) {
      if (this.isEditingWorkflow) return false;
      return this.isPending(stage);
    },
    isPending(stage) {
      if (stage.status === "in_progress" || stage.status === "completed") {
        return false;
      }
      return true;
    },
    canUpdateRevisions(stage) {
      return (
        !stage.status ||
        stage.status === "pending" ||
        stage.status === "in_progress"
      );
    },
    appendStageWithRevs: function () {
      const stage = {
        title: "Stage " + (this.wfTemplate?.tasks?.length || 0),
        type: "Stage",
        tasks: [{ type: "Revision" }, { type: "Revision" }],
      };
      const selectedDeliverable = this.deliverables.find(
        (deliverable) => deliverable.workflow_id === this.activeTab
      );
      if (selectedDeliverable.workflow.tasks) {
        // Update existing tasks array
        this.$set(
          selectedDeliverable.workflow.tasks,
          selectedDeliverable.workflow.tasks.length,
          stage
        );
      } else {
        // Create new tasks array
        this.$set(selectedDeliverable.workflow, "tasks", [stage]);
      }
    },
    removeStage: function (index) {
      this.wfTemplate.tasks.splice(index, 1);
    },
    /**
     * A Revision implies a 'First Preview'
     * We assume 0 Revisions is because there are no previews either
     */
    revisionsInStage(stage) {
      const actualRevisions = stage?.tasks?.length ?? 0;
      return Math.max(0, actualRevisions - 1);
    },
    /**
     * Take into consideration the stage's task and revision statuses
     * to determine the minimum number of tasks allowed for this stage
     */
    findMinimumTasksAllowedForStage(stage) {
      // Find the last task with a/any status
      let index = stage.tasks.findLastIndex((task) => task.status);

      // If none found (an empty stage or future stage that is pending),
      // then this stage can be empty
      if (index === -1) return 0;

      // If at least 1 task was found, the stage must have a minimum of 2 tasks (prev + rev)
      return Math.max(2, index + 1);
    },
    /**
     * Update the workflow stage with the desired number of revisions
     * We take into consideration the First Preview task
     * @param {*} stage The stage to update
     * @param {number} revisionCnt The desired number of revisions
     */
    updateStageRevisionCount(stage, revisionCnt) {
      let taskCnt = revisionCnt;
      if (revisionCnt > 0) taskCnt++; // Include a 'task' for the 'First preview'. If 0, then stick to 0 because it means no Preview nor Revisions

      // If new Rev.Cnt
      //  is more than current number of revs...add more Revs
      //  if less, then..
      //      If less than number of revs already actioned (not pending and not in progress), do nothing (do not allow)
      //      if more, remove extra/pending revs
      if (taskCnt >= stage.tasks.length) {
        // Add more revisions
        while (stage.tasks.length < taskCnt) {
          stage.tasks.push({ type: "Revision" });
        }
      } else {
        // Remove revisions
        const minTaskCnt = this.findMinimumTasksAllowedForStage(stage);
        taskCnt = Math.max(minTaskCnt, taskCnt);
        while (stage.tasks.length > taskCnt) {
          stage.tasks.pop();
        }
      }
      // We force a refresh of the method (computed prop.): revisionsInStage
      stage.tasks = [...stage.tasks];
    },
    /**
     * This is called by the parent page/component to fetch the workflow as set up by the user.
     * The number of revisions returned includes the 'First Preview'.
     *   So 4 revisions mean, 1 First Preview + 3 Revision Rounds
     * It can be submitted as is to the Workflows API
     */
    getOrderedWorkflow() {
      let newWorkflow = [...this.multiWfTemplate];
      if (
        typeof this.taWorkflowOrder === "string" &&
        this.taWorkflowOrder.length > 5 &&
        Array.isArray(JSON.parse(this.taWorkflowOrder))
      ) {
        newWorkflow.tasks = [];
        JSON.parse(this.taWorkflowOrder).forEach((entry) => {
          if (this.wfTemplate.tasks[entry.id]) {
            newWorkflow.tasks.push(this.wfTemplate.tasks[entry.id]);
          }
        });
      }

      return newWorkflow;
    },
    /**
     * Prepare the UI for the given workflow
     */
    prepareComponentForWorkflow(workflow) {
      // Toggles the 'Enable Target Dates' checkbox and shows/hides the datepicker controls
      if (workflow?.tasks?.find((stage) => stage.target_date)) {
        this.showTargetCompletion = true;
      }
    },
  },
  watch: {
    wfTemplate: function (newVal) {
      this.prepareComponentForWorkflow(newVal);
    },
    isEditingWorkflow: function () {
      window.nestable();
    },
  },
  mounted() {
    this.prepareComponentForWorkflow(this.wfTemplate);
  },
};
</script>
<style>
.tab-bar-scroll {
  display: inline-block;
  white-space: nowrap;
}

.deliverable-nav-tabs .nav-link {
  background-color: white;
}
</style>
